import default_icon from "../../assets/img/default Prfile icon.jpg";
import facebook from "../../assets/img/facebook.png";
import twitter from "../../assets/img/twitter_5969020.png";
// import instagram from "../../assets/img/instagram.png";
import whatsapp from "../../assets/img/whatsapp.png";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Swiper, SwiperSlide } from "swiper/react";
import parse from "html-react-parser";
import Loader from "../../Component/UI/Loader";
// import SideAds from "../../Component/Third-PartyAds/SideAds";
import MainAd from "../../Component/Third-PartyAds/MainAd";
import NewsLetter from "../../Component/Third-PartyAds/NewsLetter";
import { DataContext } from "../../Component/Context/SidebarContext";
import SideAd from "../../Component/Third-PartyAds/Advertise";
import TrendingPosts from "../../Component/SideNews/TrendingPost";
import { Constants } from "../../Middleware/Constant";
// import { Helmet } from "react-helmet-async";
import useFetchMetaTags from "../../Middleware/useFetchMetaTags";
import NotFound from "../NotFound";

// http:localhost:5173/horizon-inquiry-to-survey-current-sub-postmasters-on-post-office-conduct

export default function PostPage() {
  const [postDetails, setPostDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { post } = useParams();
  const { data: adsData, homeNews: trendingNews } = useContext(DataContext);
  const navigate = useNavigate();
  const ads = adsData?.data;
  const trendingData = trendingNews?.data?.trending_news;

  // const PageSlug = "Home-page";
  // const { data: tags } = useFetchMetaTags(post);

  const content =
    typeof postDetails?.data?.posts?.post_content === "string"
      ? postDetails?.data?.posts?.post_content
      : "";

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        if (!post) {
          throw new Error(`The Page You are Looking for is not Found`);
        }
        const urlEncodedData = new URLSearchParams();
        urlEncodedData.append("post_slug", post);
        const response = await fetch(Constants.backendUrl + "article-detail", {
          method: "POST",
          headers: {
            Authorization: `Basic ${Constants.token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: urlEncodedData.toString(),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();

        if (result?.code === 401) {
          //throw new Error(data?.message);
        }
        setPostDetails(result);
      } catch (error) {
        setError(error.toString());
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [post]);

  return (
    <>
      {/* <Helmet>
        <title>{`${tags?.data?.title}`}</title>
        <meta name="description" content={`${tags?.data?.description}`} />
        <link rel="canonical" href={`${tags?.data?.canonical}`} />
        <meta property="og:locale" content={`${tags?.data?.og_locale}`} />
        <meta property="og:type" content={`${tags?.data?.og_type}`} />
        <meta property="og:title" content={`${tags?.data?.og_title}`} />
        <meta
          property="og:image"
          content={`${postDetails?.data?.posts?.post_image}`}
        />
        <meta
          property="og:description"
          content={`${tags?.data?.og_description}`}
        />
        <meta property="og:url" content={`${tags?.data?.og_url}`} />
        <meta property="og:site_name" content={`${tags?.data?.og_site_name}`} />
        <meta
          property="og:updated_time"
          content={`${tags?.data?.og_updated_time}`}
        />
        <meta
          property="article:published_time"
          content={`${tags?.data?.article_published_time}`}
        />
        <meta
          property="article:modified_time"
          content={`${tags?.data?.article_modified_time}`}
        />
        <meta name="twitter:card" content={`${tags?.data?.twitter_card}`} />
        <meta name="twitter:title" content={`${tags?.data?.twitter_title}`} />
        <meta
          name="twitter:description"
          content={`${tags?.data?.twitter_description}`}
        />
      </Helmet> */}
      {
        <section className="industry_news">
          <div className="container mx-auto sm:px-7 px-4">
            {ads?.home_page_2_ads && (
              <div className="sm:hidden block mb-3">
                <MainAd adsData={ads?.home_page_2_ads} />
              </div>
            )}
            {loading === true ? (
              <>
                <Loader /> <Skeleton height={200} count={5}></Skeleton>
              </>
            ) : !postDetails?.data?.posts?.post_title ? (
              <NotFound />
            ) : (
              <>
                <div className="lg:flex bg-[#DAD9D7] p-5  rounded-[10px]">
                  <div className="lg:w-1/2 relative lg:px-[12px]">
                    <Link
                      to={`/category/${postDetails?.data?.posts?.cat?.cate_slug}`}
                      className="inner-box2 inline-block sm:text-[20px] text-justify text-[14px] uppercase text-red font-bold lg:mt-[30px]  ml-10"
                    >
                      {postDetails?.data?.posts?.cat?.cate_title}
                    </Link>
                    <h1 className="md:text-[30px] xl:text-[35px] sm:text-[25px] text-[16px] lg:max-w-[520px] lg:my-[62px] md:my-[42px] sm:-[32px] my-[20px] text-dblack font-black wow fadeInDown">
                      {postDetails?.data?.posts?.post_title}
                    </h1>
                    <div>
                      <div className="flex items-center sm:mb-7 mb-2">
                        <img
                          src={default_icon}
                          alt=""
                          className="mr-[10px] sm:w-[40px] sm:h-[40px] w-[30px] h-[30px]  object-cover rounded-full"
                        />
                        <span className=" sm:text-[.8rem] text-[12px] text-dblack">
                          {postDetails?.data?.posts?.post_author} | {""}
                          {postDetails?.data?.posts?.post_date}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="lg:w-1/2 sm:mt-10 lg:h-[400px]   mt-5 lg:mt-0">
                    <img
                      src={postDetails?.data?.posts?.post_image}
                      alt=""
                      className="w-full max-h-52 sm:max-h-96 lg:max-h-max lg:h-full object-contain rounded-[10px]"
                    />
                  </div>
                </div>
                <div className="news-social flex justify-between items-center pt-4 sm:pb-10 gap-5  pb-6 lg:pl-1 lg:pr-5 pl-2 sm:pl-0">
                  <div className="flex items-center space-x-2">
                    <p className="sm:text-xs text-[9px] text-nowrap text-black">
                      Follow Us :
                    </p>
                    <a
                      href={postDetails?.data?.posts?.follow_us?.facebook}
                      aria-label="link facebook"
                      target="_blank"
                      className="sm:w-[25px]"
                    >
                      <img
                        src={facebook}
                        alt=""
                        className="sm:w-full sm:h-full h-[20px] w-[20px] rounded-full"
                      />
                    </a>
                    <a
                      href={postDetails?.data?.posts?.follow_us?.twitter}
                      aria-label="link twitter"
                      target="_blank"
                      className="sm:w-[25px]"
                    >
                      <img
                        src={twitter}
                        alt=""
                        className="sm:w-full sm:h-full h-[20px] w-[20px] rounded-full"
                      />
                    </a>
                    {/* <a href="#" className="w-[25px]">
                  <img src={instagram} alt="" className="w-full rounded-full" />
                </a> */}
                    <a
                      href={postDetails?.data?.posts?.follow_us?.whatsapp}
                      aria-label="link whatsapp"
                      target="_blank"
                      className="sm:w-[25px]"
                    >
                      <img
                        src={whatsapp}
                        alt=""
                        className="sm:w-full sm:h-full h-[20px] w-[20px] rounded-full"
                      />
                    </a>
                  </div>
                  <div>
                    {postDetails?.data?.posts?.post_image_caption && (
                      <p className="sm:text-xs md:block hidden text-[9px]   text-black">
                        {`${postDetails?.data?.posts?.post_image_caption}`}
                      </p>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>

          {/* Content start */}
          {loading === true ? (
            <>
              <Loader />
            </>
          ) : !postDetails?.data?.posts?.post_title ? (
            ""
          ) : (
            <div className="mb-7">
              <div className="container mx-auto sm:px-7 px-2">
                <div className="grid grid-cols-12 gap-5">
                  <div className="lg:col-span-9 md:col-span-8 col-span-12 md:border-r-2 border-greyb sm:pr-8 sm:px-0 px-4 md:pr-4 md:text-[15px] text-[.8rem] post-content">
                    {parse(content)}
                    {/* Swiper slider */}

                    {postDetails?.data?.related_posts?.length > 0 ? (
                      <div className="bg-[#E4E3E1] p-5 rounded-lg ml-0">
                        <Swiper
                          className="swiper mySwiper2"
                          spaceBetween={20}
                          slidesPerView={1}
                          autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                          }}
                          breakpoints={{
                            320: { slidesPerView: 1.5 },
                            460: { slidesPerView: 2 },
                            768: { slidesPerView: 2 },
                            1120: { slidesPerView: 3 },
                          }}
                        >
                          <div className="swiper-wrapper">
                            {postDetails?.data?.related_posts?.map(
                              (post, i) => {
                                return (
                                  <SwiperSlide
                                    key={i}
                                    className="swiper-slide !bg-transparent"
                                  >
                                    <div className="pr-5 md:pl-0 border-r border-grey">
                                      <div className="h-30 md:h-auto w-full  overflow-hidden">
                                        <Link
                                          to={`/${post.post_slug}`}
                                          aria-label={`${post.post_slug}`}
                                        >
                                          <img
                                            src={post.post_image}
                                            alt=""
                                            className="rounded-lg w-full  h-20 object-cover   md:w-auto md:h-40"
                                          />
                                        </Link>
                                      </div>
                                      <Link to={`/${post.post_slug}`}>
                                        <span className="sm:text-xs text-[8px] text-red font-black  uppercase my-3">
                                          {post.cat.cate_slug}
                                        </span>
                                      </Link>
                                      <Link
                                        to={`/${post.post_slug}`}
                                        aria-label={`${post.post_slug}`}
                                      >
                                        <p className=" mt-auto  lg:text-[14px] lg:min-h-10 min-h-9 truncate-text sm:text-[12px] text-[12px] hover:text-red">
                                          {post.post_title}
                                        </p>
                                      </Link>
                                    </div>
                                  </SwiperSlide>
                                );
                              }
                            )}
                          </div>
                        </Swiper>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  {/* Sidebar */}
                  <div className="lg:col-span-3 md:col-span-4 col-span-12 ">
                    <SideAd htmlData={ads?.mpu_1_ads} />
                    <SideAd htmlData={ads?.mpu_2_ads} />
                    <SideAd htmlData={ads?.mpu_3_ads} />
                    <SideAd htmlData={ads?.mpu_4_ads} />
                    <SideAd htmlData={ads?.mpu_5_ads} />
                    <SideAd htmlData={ads?.mpu_6_ads} />
                    <div className="p-3 mt-5 mb-[30px] ">
                      <TrendingPosts trendingPost={trendingData} />
                    </div>
                    <NewsLetter />
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      }
    </>
  );
}
