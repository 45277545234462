import { useState } from "react";
import { Link } from "react-router-dom";
// import Loader from "../UI/Loader";
import { Constants } from "../../Middleware/Constant";

const initialFormData = {
  user_name: "",
  user_email: "",
  user_role: "",
  remember: false,
};

export default function NewsLetter() {
  const [formData, setFormData] = useState({ initialFormData });
  const [success, setSuccess] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState("");

  const clearError = () => {
    setErrors("");
    setSubmissionError("");
  };

  const clearSuccess = () => {
    setSuccess("");
  };

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: type === "checkbox" ? checked : value,
    }));
  };

  const validate = () => {
    const newErrors = {};

    if (!formData.user_name) {
      newErrors.user_name = "Name is required";
    } else if (/\d/.test(formData.user_name)) {
      newErrors.user_name = "Name cannot contain numbers";
    }

    if (!formData.user_email) {
      newErrors.user_email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.user_email)) {
      newErrors.user_email = "Email address is invalid";
    }

    if (!formData.user_role) {
      newErrors.user_role = "Please select a role";
    } else if (
      !["Retailer", "Wholesaler", "Client"].includes(formData.user_role)
    ) {
      newErrors.user_role = "Invalid role selected";
    }

    if (!formData.remember) {
      newErrors.remember = "You must accept the privacy rules";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      setTimeout(clearError, 3000);
    }
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      try {
        setIsSubmitting(true);
        setSubmissionError("");
        setSuccess("");
        const params = new URLSearchParams();
        params.append("user_email", formData.user_email);
        params.append("user_name", formData.user_name);
        params.append("user_role", formData.user_role);

        const response = await fetch(Constants.backendUrl + "newsletter", {
          method: "POST",
          headers: {
            Authorization: `Basic ${Constants.token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: params.toString(),
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        if (result.code === 401)
          throw new Error(
            "There was an error submitting the form. Please try again."
          );
        setSuccess("You have successfully subscribed to our newsletter");
        setFormData(initialFormData);
      } catch (error) {
        setSubmissionError(error.message);
      } finally {
        setIsSubmitting(false);
        setTimeout(clearSuccess, 3000);
      }
    }
  };

  return (
    <div>
      <div className="bg-[#5A5A5A] text-white shadow-md px-5 py-6">
        <h1 className="text-[20px] font-black mb-4">NEWSLETTER</h1>
        <div className="h-[10px] border-y border-[#acacac] mt-5 mb-14"></div>
        <form onSubmit={handleSubmit}>
          <div className="mb-5">
            <input
              type="text"
              id="user_name"
              className="shadow-sm w-full px-3 py-2 border border-gray-300 bg-[#5A5A5A] text-white focus:outline-none text-[10px] font-openSans"
              placeholder="Name"
              value={formData.user_name}
              onChange={handleChange}
              required
            />
            {errors.user_name && (
              <p className="text-red bg-white text-[10px]">
                {errors.user_name}
              </p>
            )}
          </div>
          <div className="mb-5">
            <input
              type="email"
              id="user_email"
              className="shadow-sm w-full px-3 py-2 bg-[#5A5A5A] text-white border border-gray-300 focus:outline-none text-[10px] font-openSans"
              placeholder="Email"
              value={formData.user_email}
              onChange={handleChange}
              required
            />
            {errors.user_email && (
              <p className="text-red bg-white text-[10px]">
                {errors.user_email}
              </p>
            )}
          </div>
          <div className="mb-5">
            <select
              id="user_role"
              className="shadow-sm w-full px-3 py-2 bg-[#5A5A5A] text-white border border-gray-300 focus:outline-none text-[10px] font-openSans"
              value={formData.user_role}
              onChange={handleChange}
              aria-label="select who you are"
              required
            >
              <option value="">I Am a</option>
              <option value="Retailer">Retailer</option>
              <option value="Wholesaler">Wholesaler</option>
              <option value="Client">Client</option>
            </select>
            {errors.user_role && (
              <p className="text-red bg-white text-[10px]">
                {errors.user_role}
              </p>
            )}
          </div>
          <div className="flex items-center justify-between flex-col mt-10 mb-10">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="remember"
                className="h-3 w-3 rounded border-gray-300"
                checked={formData.remember}
                onChange={handleChange}
              />

              <label
                htmlFor="remember"
                className="ml-2 block text-[10px] text-white dark:text-gray-300 font-openSans"
              >
                Subscribing I accept the{" "}
                <Link to={"privacy-policy"} className="text-white">
                  {" "}
                  privacy rules{" "}
                </Link>{" "}
                of this site
              </label>
            </div>
            {errors.remember && (
              <p className="text-red bg-white text-[10px]">{errors.remember}</p>
            )}
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-black text-white bg-red hover:bg-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:red-500"
          >
            {isSubmitting ? "please wait..." : "Subscribe Now"}
          </button>
          {submissionError && (
            <p className="text-red mt-2 text-[10px]">{submissionError}</p>
          )}
          {success && (
            <p className="text-green-500 mt-2 text-[10px]">{success}</p>
          )}
        </form>
      </div>
    </div>
  );
}
