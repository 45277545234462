// import React from "react";
// import Image_8 from "../../assets/img/Image 8.png";
import parse from "html-react-parser";

export default function MainAd({ adsData }) {
  const content = typeof adsData === "string" ? adsData : "";
  return (
    <div className="col-span-12 bg-[#E8E8E8] rounded-md px-5 py-3 flex justify-center">
      {parse(content) ? parse(content) : ""}
    </div>
  );
}
