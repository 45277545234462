import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import noImage from "../../assets/img/no-image (1).png";
import editIcon from "../../assets/img/pencil.png";

const TrendingPosts = ({ trendingPost, isLoading2, error2, user }) => {
  return (
    <div className="col-span-12">
      <div className="mt-8 mb-5 relative flex items-center">
        <h2 className="text-[14px] text-red font-bold pl-8 uppercase inner-box">
          {trendingPost?.trending_news_title}
        </h2>
        <div className="border-y-2 h-[13px] md:w-[225px] w-full ml-[10px]"></div>
      </div>
      {!error2 && trendingPost?.posts?.length > 0 ? (
        trendingPost?.posts.slice(0, 4)?.map((item, i) => (
          <div
            key={i}
            className={`grid grid-cols-12  gap-3 relative  ${
              i > 2 ? "" : "border-b border-greyb pb-5 mb-5"
            } ${user ? "group/item edit-hover" : ""}`}
          >
            <div className="md:col-span-4 col-span-3 ">
              <Link
                to={`/${item.post_slug}`}
                aria-label={`${item.post_slug}`}
                className="relative min-w-[70px] max-w-[85px] w-[80px] block edit-img-hover"
              >
                <img
                  src={
                    typeof item.post_image === "string" && item.post_image
                      ? item.post_image
                      : noImage
                  }
                  alt="flash news"
                  className="md:min-h-20 md:max-h-20 sm:max-h-28 w-full sm:min-h-28 min-h-20 max-h-20 object-cover rounded-[10px] "
                />
              </Link>
            </div>
            <div className="md:col-span-8 col-span-9 md:pl-5 lg:pl-3">
              <Link to={`/category/${item?.cat?.cate_slug}`}>
                <span className="text-[10px] text-red block font-openSans font-semibold uppercase mb-2">
                  {item?.cat?.cate_title}
                </span>
              </Link>
              <Link to={`/${item.post_slug}`} aria-label={`${item.post_slug}`}>
                <p className="font-bold text-xs lg:pr-0 sm:pr-5 md:pr-0 truncate-text  wow fadeInDown hover:text-red">
                  {item?.post_title}
                </p>
              </Link>
            </div>

            {user && (
              <a href={`${item.post_admin_link}`} target="_blank" className="">
                <div className="absolute top-[-10px] right-0 z-[2] invisible group-hover/item:visible  cursor-pointer font-openSans bg-[#fff] hover:bg-blue-400 text-sm px-3 py-1 font-bold rounded-lg shadow-md shadow-black">
                  <img
                    src={editIcon}
                    className="inline-block h-2 w-2  mb-1"
                    alt="edit"
                  />
                </div>
              </a>
            )}
          </div>
        ))
      ) : error2 ? (
        <img
          src={noImage}
          className="lg:pt-0 pt-5 w-full md:max-h-36 sm:max-h-72 rounded-[10px]"
          alt="placeholder"
        ></img>
      ) : (
        <Skeleton height={150}></Skeleton>
      )}
    </div>
  );
};

export default TrendingPosts;
