import parse from "html-react-parser";

export default function SideAd({ htmlData }) {
  const content = typeof htmlData === "string" ? htmlData : "";
  return (
    <>
      {htmlData ? (
        <div className="lg:h-[250px]  hidden md:block">
          {parse(content) ? parse(content) : ""}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
