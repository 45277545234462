import React from "react";
import { Link } from "react-router-dom";
import NotfoundGif from "../assets/img/AT NotFound.gif";

export default function NotFound() {
  return (
    <section className="pb-5 news mb-20 bg-blur">
      <div className="container mx-auto px-7">
        <div className="flex items-center flex-col">
          <h1 className="sm:text-2xl text:sm">{`Ooops......${"\u00A0".repeat(
            2
          )}Error 404`}</h1>
          <div>
            <p className="sm:text-sm text-xs ml-5 sm:ml-0">
              Sorry, but the page you are looking for doesn't exist
            </p>
          </div>
          <div className="flex justify-center">
            <img
              className="w-1/2"
              // src="https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif"
              src={NotfoundGif}
              alt="404"
            />
          </div>
          <div>
            <p className="md:text-sm text-xs">
              <Link
                to={"/"}
                className="border bg-black text-white py-1 px-2 rounded-md hover:bg-red"
              >
                Back to Home
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
